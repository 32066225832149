/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useContext, useEffect } from 'react';
import PayPalBtn from './PayPalBtn';
import { WizardContext } from './Wizard';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Complete from './Complete';
import _ from 'lodash';
import GraphQLError from '../../form/GraphQLError';
import FindPromoCode from './FindPromoCodeA';
import { payPlans } from '../BusinessBox/PaypalBusinessPlans';

const MUTATION_PURCHASE_BUSINESS_PLAN = gql`
  mutation PurchaseBusinessPlan(
    $info: BusinessSignup
    $info2: BusinessNetworkingSignup
  ) {
    purchaseBusinessPlan(info: $info, info2: $info2) {
      id
    }
  }
`;

function PaypalSubscriptions({ setFreePlan }) {
	const sharedValues = useContext(WizardContext);
	const [orderID, setOrderID] = useState('');
	const [planID, setPlanID] = useState('');
	const [ppError, setPPError] = useState('');
	const [selectedPlan, setSelectedPlan] = useState('');
	const [free, setFree] = useState(false);

	useEffect(() => {
		setFreePlan(free);
	}, [free]);

	useEffect(() => {
		if (selectedPlan) {
			return setPlanID(selectedPlan);
		}
		payPlans.map((pp) => {
			if (pp.plan == sharedValues.plan) {
				return setPlanID(pp.planID);
			}
		});
	}, [selectedPlan]);
	const [purchasePlan, { data, loading, error }] = useMutation(
		MUTATION_PURCHASE_BUSINESS_PLAN
	);

	console.log('sharedValues', sharedValues);
	const paypalSubscribe = (data, actions) => {
		console.log('planID', planID);
		return actions.subscription.create({
			plan_id: planID,
			intent: 'subscription',
		});
	};

	const paypalOnError = (err) => {
		setPPError('Paypal Error, Please Try Again');
	};
	const paypalOnApprove = (data, detail) => {
		console.log('PaypalOnApprove data',data);
		sharedValues.orderId = data.subscriptionID;
		const property =
      (sharedValues.plan === 6 ||
      sharedValues.plan === 8)
      	? 'info'
      	: 'info2';
		purchasePlan({
			variables: { [property]: { ...sharedValues } },
		});
		const { orderID } = data;
		setOrderID(orderID);
	};

	return (
		<div className="container">
			<div
				style={{
					color: '#000000DE',
					fontSize: '14px',
					marginBottom: '4px',
				}}
			>
				{!free && <strong>Please Select a Payment Method</strong>}
			</div>
			<div>
				{!orderID && !free && (
					<PayPalBtn
						createSubscription={paypalSubscribe}
						onApprove={paypalOnApprove}
						catchError={paypalOnError}
						onError={paypalOnError}
						onCancel={paypalOnError}
					/>
				)}
				{!orderID && (
					<FindPromoCode setFree={setFree} setSelectedPlan={setSelectedPlan} />
				)}
				{free && <strong>Please Click on the Purchase</strong>}
			</div>
			{orderID && <Complete {...{ orderID }} />}
			{!orderID && (
				<GraphQLError error={ppError || error}>There was an Error</GraphQLError>
			)}
		</div>
	);
}
export default PaypalSubscriptions;
