import React from 'react';
import { Form } from 'semantic-ui-react';
import FSStripe from '../../form/FSStripe';
import { Field } from 'react-final-form';
import styles from '../BuyCard/checkoutForm.module.scss';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from 'react-stripe-elements';
import _ from 'lodash';
import FindPromoCode from '../FindPromoCode';
import OrderDetails from '../OrderDetails';

const createOptions = (fontSize, padding) => {
	return {
		style: {
			base: {
				fontSize,
				color: '#424770',
				letterSpacing: '0.025em',
				fontFamily: 'Source Code Pro, monospace',
				'::placeholder': {
					color: '#aab7c4'
				},
				padding
			},
			invalid: {
				color: '#9e2146'
			}
		}
	};
};

const calculatePrice = (total, code) => {
	if(code) {
		if(code.amount_off !== null) {
			return total - (code.amount_off/100);
		} else if (code.percent_off !== null) {
			return total * ((100-code.percent_off)/100); 
		}
	}
	return total; 
};

const PaymentPage = ({ values, code, onCodeApplied, setFree }) => {
	
	React.useEffect(() => {
		const personalPrice = values.totalPlans.personal * 49.95;
		const familyPrice = values.totalPlans.family * 99.90;
		const isFree = calculatePrice(personalPrice + familyPrice, code) === 0;
		setFree(isFree);
	}, [values.plan, code]);

	return (
		<>
			<h2 className="formHeader">Payment</h2>
			<p className="subheader">We will charge your purchase to the card below</p>
			<div className={styles.section}>
				<div className="stripe">
					<Form.Group widths="equal">
						<Form.Field>
							<label>Name on Card
								<Field
									name="stripeName"
									component="input"
									style={{width: '100%'}}
									placeholder="Cardholder Name"
								/>
							</label>
						</Form.Field>
						<FSStripe
							{...createOptions(20)}
							component={CardNumberElement}
							name="stripeCard"
							label="Card #"
						/>
					</Form.Group>

					<Form.Group widths="equal">
						<FSStripe
							{...createOptions(20)}
							component={CardExpiryElement}
							name="stripeExpiry"
							label="EXP"
						/>

						<FSStripe
							component={CardCvcElement}
							{...createOptions(20)}
							name="stripeCVC"
							label="CVC"
						/>
							
						<Form.Field>
							<label>
									Zip
								<Field
									name="stripeZip"
									component="input"
									style={{width: '100%'}}
									placeholder="Zip Code"
								/>
							</label>
						</Form.Field>
					</Form.Group>
				</div>
				<div className={styles.poweredBy}>
					<a href="https://www.stripe.com" target="_blank" rel="noopener noreferrer">
						<img src={require('../../../assets/images/common/powered_by_stripe.svg')} />
					</a>
				</div>
			</div>
			<FindPromoCode
				containerized
				onComplete={code => onCodeApplied(code)}
			/>
			<OrderDetails
				items={[
					{ name: "Individual Plan", price: 99.90, quantity: values.totalPlans.personal },
					{ name: "Family Plan", price: 99.90, quantity: values.totalPlans.family },
				]}
				code={code}
			/>
		</>
	);
};

export default PaymentPage;

export const validate = (values) => {
	const errors = {};

	if(!values.isFree) {
		if(!values.stripeName) {
			errors.stripeName = 'Required Field';
		}

		if(!values.stripeCard) {
			errors.stripeCard = 'Required Field';
		} else if (!_.get(values, 'stripeCard.complete')) {
			errors.stripeCard = 'Please complete this field';
		}

		if(!values.stripeExpiry) {
			errors.stripeExpiry = 'Required Field';
		} else if (!_.get(values, 'stripeExpiry.complete')) {
			errors.stripeExpiry = 'Please complete this field';
		}

		if(!values.stripeCVC) {
			errors.stripeCVC = 'Required Field';
		} else if (!_.get(values, 'stripeCVC.complete')) {
			errors.stripeCVC = 'Please complete this field';
		}

		if(!values.stripeZip) {
			errors.stripeZip = 'Required Field';
		}
	}

	return errors;
};