/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { injectStripe, Elements, StripeProvider } from "react-stripe-elements";
import Optionals, {
	LoyaltyForm,
	SocialMediaForm,
	PresentationForm,
	LoyaltyFormValidation,
	SocialMediaFormValidation,
	PresentationFormValidation,
} from "./Sections/Optionals";
import Wizard from "./Wizard";
import {
	BasicInfo,
	BasicInfoValidation,
	StripeSection,
	StripeValidation,
} from "./Sections";
import PlanSelectSection from "./Sections/BusinessPlans";
import { PlanSelectValidation } from "./Sections/BusinessPlans/PlanSelectSection";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import LoginOrRegister from "../LoginOrRegister/LoginOrRegister";
import Complete from "./Complete";
import { Button } from "semantic-ui-react";
// for networking plans
import EmployeesPage, {
	validate as EmployeePageValidate,
} from "./Sections/Affiliate/Employees";
import AddressesPage, {
	validate as AddressesValidate,
} from "./Sections/Affiliate/AddressesPage";
import PromotionsPage, {
	validate as PromotionsValidate,
} from "./Sections/Affiliate/PromotionsPage";
import TermsPage, {
	validate as TermsValidate,
} from "./Sections/Affiliate/TermsPage";
import PaypalSubscriptions from "./PaypalSubscriptions";
// import QUERY_CURRENT_USER from '../../../queries/user'
const MUTATION_PURCHASE_BUSINESS_PLAN = gql`
  mutation PurchaseBusinessPlan(
    $info: BusinessSignup
    $info2: BusinessNetworkingSignup
  ) {
    purchaseBusinessPlan(info: $info, info2: $info2) {
      id
    }
  }
`;

const QUERY_CURRENT_USER = gql`
  {
    user {
      id
      firstname
      lastname
      email
      accountType
      hasBusinessPlan
    }
  }
`;

const calculatePrice = (total, code) => {
	if (code) {
		if (code.amount_off !== null) {
			return total - code.amount_off / 100;
		} else if (code.percent_off !== null) {
			return total * ((100 - code.percent_off) / 100);
		}
	}
	return total;
};

const BusinessPlanSignupContent = ({ stripe, plan }) => {
	const [freePlan, setFreePlan] = useState(false);
	console.log("freePlan", freePlan);
	const userInfo = useQuery(QUERY_CURRENT_USER, {
		fetchPolicy: "network-only",
	});
	console.log("userInfo>>>>>>>>", userInfo.data?.user?.hasBusinessPlan);
	console.log("userInfo Data>>>>>>>>", userInfo.data);
	const [purchasePlan, { data, loading, error }] = useMutation(
		MUTATION_PURCHASE_BUSINESS_PLAN
	);
	const [code, onCodeApplied] = React.useState(null);

	useEffect(() => {
		if (code) {
			setFreePlan(true);
		} else {
			setFreePlan(false);
		}
	}, [code]);

	const onSubmit = async (variables) => {
		const property = variables.plan === 6 || variables.plan === 7 || variables.plan === 9 ? "info" : "info2";
	  
		if (freePlan) {
		  purchasePlan({
			variables: { [property]: _.omit(variables, ["stripeCard", "stripeCVC", "stripeExpiry"]) },
		  });
		  return;
		}
	  
		try {
		  const { token, error: stripeError } = await stripe.createToken();
	  
		  if (stripeError) {
			console.error("Stripe Error:", stripeError.message);
			alert("Payment failed: " + stripeError.message);
			return;
		  }
	  
		  const cleanedVars = _.omit(variables, [
			"stripeCard",
			"stripeCVC",
			"stripeExpiry",
			"stripeZip",
			"stripeName",
		  ]);
	  
		  cleanedVars.token = token.id; // ✅ correct field
	  
		  purchasePlan({
			variables: {
			  [property]: cleanedVars,
			},
		  });
		} catch (err) {
		  console.error("Unexpected error:", err);
		  alert("Something went wrong with the payment.");
		}
	  };
	  
	  
	  

	const orderID = _.get(data, "purchaseBusinessPlan.id");
	console.log("Purchase Mutation Data", data);
	console.log("orderID", orderID);

	if (orderID) {
		return <Complete {...{ orderID }} />;
	}

	if (userInfo.loading) {
		return <div>Loading...</div>;
	}

	if (userInfo.data?.user?.hasBusinessPlan) {
		console.log(
			"HAS BUSINESSS PLAN >>>>",
      userInfo.data?.user?.hasBusinessPlan
		);
		return (
			<div className="purchase-complete">
				<i className="fas fa-check"></i>
				<h3>Manage your Business Plan</h3>
				<p>Thanks for signing up for a DNP Business Plan</p>
				<p>
          Click the button below to go to our backoffice. There you can manage
          your membership, add employees, etc.
				</p>
				<div>
					<Button
						content="Go to the Backoffice"
						size="huge"
						color="blue"
						type="button"
						as="a"
						target="_blank"
						href="https://office.peoplefourpeople.com"
					/>
				</div>
			</div>
		);
	}

	if (!_.get(userInfo, "data.user.id")) {
		return <LoginOrRegister onLogin={() => userInfo.refetch()} />;
	}

	return (
		<div>
			<Wizard
				free={true}
				onSubmit={onSubmit}
				initialValues={{
					accountID: _.get(userInfo, "data.user.id"),
					country: "US",
					plan,
				}}
				loading={loading}
			>
				<Wizard.Page validate={BasicInfoValidation}>
					<BasicInfo />
				</Wizard.Page>

				{/* <Wizard.Page
					when={'plan'}
					is={9}
					validate={EmployeePageValidate}
				>
					<EmployeesPage />
				</Wizard.Page> */}

				{/* <Wizard.Page
					when={'plan'}
					is={[8,9]}
					validate={AddressesValidate}
				>
					<AddressesPage />
				</Wizard.Page> */}

				<Wizard.Page when={"plan"} is={[8, 9]} validate={PromotionsValidate}>
					<PromotionsPage />
				</Wizard.Page>

				<Wizard.Page when={"plan"} is={[6, 7]}>
					<Optionals accountType={_.get(userInfo, "data.user.accountType")} />
				</Wizard.Page>

				<Wizard.Page when="loyalty" is={true} validate={LoyaltyFormValidation}>
					<LoyaltyForm />
				</Wizard.Page>

				<Wizard.Page
					when="socialMedia"
					is={true}
					validate={SocialMediaFormValidation}
				>
					<SocialMediaForm />
				</Wizard.Page>

				<Wizard.Page
					when="presentation"
					is={true}
					validate={PresentationFormValidation}
				>
					<PresentationForm />
				</Wizard.Page>

				<Wizard.Page validate={TermsValidate}>
					<TermsPage />
				</Wizard.Page>

				<Wizard.Page validate={StripeValidation}>
					{/* <PaypalSubscriptions setFreePlan={setFreePlan} /> */}
					<StripeSection
						error={error}
						onCodeApplied={onCodeApplied}
						code={code}
						trial={true}
					/>
				</Wizard.Page>
			</Wizard>
		</div>
	);
};

const _BusinessPlanSignupContent = injectStripe(BusinessPlanSignupContent);

const BusinessPlanSignup = (props) => {
	return (
		<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
			<Elements>
				<_BusinessPlanSignupContent {...props} />
			</Elements>
		</StripeProvider>
	);
};

export default BusinessPlanSignup;
